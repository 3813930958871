.mainContainer {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.pageDescription {
    font-size: 16px;
    font-family: 'MavenPro Regular';
    line-height: 24px;
}

.headerContainer {
    display: flex;
    justify-content: flex-start;
    gap: 48px;
    align-items: flex-start;
}

.filterContainer {
    display: flex;
    flex-direction: column;
}

.filterHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.selectionOptions{
    display: flex;
    align-items: center;
    gap: 20px;
    color: #606060;
    font-family: 'Giorgio';
    -webkit-text-stroke: 0.5px #606060;
    font-size: 18px;
    padding: 12px 0px;
}

.selectionOptions > div {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.downloadIcon{
    width: 22px;
    height: 22px;
}

.cancelSelection {
    color: #FF2F2F;
    -webkit-text-stroke: 0.5px #FF2F2F;
}
.cancelSelectionIcon{
    width: 18px;
    height: 18px;
}

.filterTitle{
    font-family: 'Giorgio';
    color: var(--primary-color);
    font-size: 18px;
    -webkit-text-stroke: 0.5px var(--primary-color);
}

.filterRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 25px;
    flex-wrap: wrap;
    align-items: center;
}

.filters{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
}

.radioLabel{
    font-family: 'Giorgio' !important;
    font-size: 18px !important;
    -webkit-text-stroke: 0.5px #606060 !important;
    transform: translateY(3px);
}

.tableActionContainer {
    flex-grow: 1;
    justify-self: flex-end;
    display: flex;
    justify-content: flex-end;
    gap: 24px;
}

.datepicker {
    max-width: 120px;
    text-align: center;
}

.inputContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    min-height: 60px;
    padding-top: 16px;
}

.inputLabel {
    font-family: "Giorgio";
    font-size: 17px;
    line-height: 20px;
    color: var(--secondary-color);
    -webkit-text-stroke: 1px var(--secondary-color);
    padding-right: 12px;
    padding-top: 12px;
}

.tableMainContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
}

.tableMessage {
    font-family: "MavenPro Regular";
    font-size: 15px;
    letter-spacing: 0px;
    line-height: 20px;
    color: #393939;
    width: 70%;
    display: inline-block;
    margin-bottom: 20px;
}

.tableInfo {
    font-family: "MavenPro Regular";
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 20px;
    color: #343434;
    width: 70%;
    display: inline-block;
    margin-bottom: 20px;
}

.tableWhiteBox {
    border-radius: 30px;
    background-color: #ffffff;
    /*padding-bottom: 150px;*/
    padding: 40px 16px;
    margin: 0;
    box-shadow: 0 3px 45px #00000029;
    text-align: center;
    margin-bottom: 40px;
}

.tableLoadingContainer {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.tableCloseCashRegisterBox {
    margin-top: 40px;
    /*margin-bottom: 20px;*/
    align-self: center;
}

.tableCurrentDateTimeLabel {
    font-family: "MavenPro Medium";
    font-size: 18px;
    letter-spacing: -0.18px;
    line-height: 21px;
    color: var(--primary-color);
    margin-bottom: 15px;
}


.downloadDocImg {
    width: 48px;
    height: 48px;
}


@media (max-width: 1368px) {
    .headerContainer {
        justify-content: center;
        flex-wrap: wrap;
    }

    .tableActionContainer {
        justify-content: center;
    }
}


@media (max-width: 768px) {
    .tableWhiteBox {
        background-color: transparent;
        box-shadow: none;
    }
}
