.root {
  z-index: 3000 !important;
}

.paper {
  border-radius: 20px !important;
  padding: 32px 0;
}
.container {
  background-image: linear-gradient(180deg, var(--secondary-color)b3 0%, #00bf87 100%);
}

.containerBlue {
  background-image: linear-gradient(180deg, #00aaebb3 0%, #008abf 100%);
}

.content {
  font-family: "MavenPro Regular";
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #343434;
  text-align: center;
}

.onlyContent {
  font-family: "Giorgio";
  font-size: 15px;
  letter-spacing: 1.5px;
  line-height: 22px;
  color: var(--primary-color);
  /* -webkit-text-stroke: 0.7px var(--primary-color); */
  text-align: center;
}

.title,
.title h2 {
  font-family: "Giorgio" !important;
  font-size: 18px !important;
  letter-spacing: 1.5px !important;
  line-height: 22px !important;
  color: var(--alert-color) !important;
  -webkit-text-stroke: 0.7px var(--alert-color) !important;
  text-align: center !important;
  padding-top: 0px;
}

.actions {
  margin-top: 20px;
  justify-content: space-around !important;
}

.closeButtonContainer {
  position: absolute;
  top: 8px;
  right: 8px;
}

.closeButtonContainer img{
  width: 14px;
  height: 14px;
}

