.tableBox {
  width: 100%;
}

.avatarIcon {
  width: 4em;
  height: 4em;
  position: relative;
  clip-path: circle(50%);
  object-fit: cover;
}

.avatarIcon:before {
  content: " ";
  height: 50px;
  width: 50px;
}

.avatarName {
  background-color: var(--primary-color);
  color: #ffffff;
  font-size: 24px;
  width: 64px;
  height: 64px;
  border-radius: 64px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.miniComponentContainer {
  margin-bottom: 24px;
}

@media only screen and (max-width: 480px) {
  .avatarIcon {
    width: 32px;
    height: 32px;
  }
}

.miniTableSkeletonContainer {
  margin-top: 5%;
  display: grid;
  column-gap: 2px;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;

  grid-template-areas:
    "a1 b1"
    "a2 b2"
    "a3 b3"
    "a4 b4"
    "a5 b5"
    "a6 b6"
    "a7 b7"
    "a8 b8"
    "a9 b9"
    "a10 b10";
}
