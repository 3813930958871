.boxContainer {
  margin-top: 40px;
}

.link {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.link p {
  padding: 0px;
  margin: 0px;
}

.green {
  color: var(--secondary-color);
  font-family: "MavenPro Medium";
}

.optionLabel {
  font-family: "Giorgio" !important;
  font-size: 18px;
  color: var(--primary-color);
  line-height: 26px;
  letter-spacing: 0.9px;
  width: 100%;
  justify-content: space-between;
  margin: 0px;
  -webkit-text-stroke: 0.7px var(--primary-color);
  text-align: left;
  margin-left: 0;
}

.optionLabelRoot {
  width: 100%;
  margin-left: 0px;
  text-align: left;
  justify-content: space-between;
}

.optionLabelLabel {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
