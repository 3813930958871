@font-face {
  font-family: "MavenPro Black";
  src: url("./assets/fonts/MavenPro-Black.ttf") format("truetype");
}

@font-face {
  font-family: "MavenPro Bold";
  src: url("./assets/fonts/MavenPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "MavenPro Medium";
  src: url("./assets/fonts/MavenPro-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "MavenPro Regular";
  src: url("./assets/fonts/MavenPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Giorgio";
  src: url("./assets/fonts/Giorgio.ttf") format("truetype");
  src: url("./assets/fonts/Giorgio.woff") format("woff");
}

@font-face {
  font-family: "Giorgio Thin";
  src: url("./assets/fonts/Giorgio-Thin.ttf") format("truetype");
  src: url("./assets/fonts/Giorgio-Thin.woff") format("woff");
}

@font-face {
  font-family: "BalooBhaina2 ExtraBold";
  src: url("./assets/fonts/BalooBhaina2-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "BalooBhaina2 Bold";
  src: url("./assets/fonts/BalooBhaina2-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "BalooBhaina2 Medium";
  src: url("./assets/fonts/BalooBhaina2-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "BalooBhaina2 SemiBold";
  src: url("./assets/fonts/BalooBhaina2-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "BalooBhaina2 Regular";
  src: url("./assets/fonts/BalooBhaina2-Regular.ttf") format("truetype");
}

body,
html {
  --primary-color: #00a2e0;
  --secondary-color: #00e091;
  --alert-color: #f65626;

  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  box-sizing: border-box;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

#root {
  --min-content-width: 500px;
  --min-width: 768px;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  margin-top: env(safe-area-inset-top);
  min-width: var(--min-width);
  /* background-color: var(--primary-color);
  background-image: url( "./assets/background.svg" );
  background-repeat: no-repeat;
  background-size: 100% auto; */
}

.App {
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

.BottomBarContainer {
  width: 100vw;
  height: auto;
  position: fixed;
  bottom: 0;
  background-image: url("./assets/bottom-bar.png");
  background-size: 100% 100%;
  background-position: 0 40px;
  background-repeat: no-repeat;
}

.BottomBarCircle {
  background-image: url("./assets/bottom-bar-center.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 40px;
  width: 100vw;
  height: 100%;
  padding-top: 40px;
}

.BottomBarCircle .BottomBig .MuiSvgIcon-root {
  font-size: 80px;
  position: relative;
  top: -20px;
}

/*.grecaptcha-badge{
  visibility: hidden;
}*/

.app-bar-title {
  font-family: "Giorgio";
  font-size: 16px;
  letter-spacing: 1.6px;
}

.logo-container {
  width: 121px;
  margin-left: 24px;
}

.logo-container img {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.titleH2 {
  font-family: "MavenPro Medium";
  font-size: 20px;
  margin: 20px 0;
  margin-bottom: 32px;
  color: var(--secondary-color);
}


@media (max-width: 768px) and (orientation: portrait) {
  .page {
    padding-top: calc(40vh - 140px);
  }
  
  #root{
    --min-content-width: 100%;
    --min-width: 100%;
  }
}
