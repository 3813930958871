.contentProfile {
  border-radius: 30px 30px 0 0;
  background-color: #ffffff;
  width: 100%;
  padding-bottom: 150px;
  padding-top: 0px;
  margin: 0;
  box-shadow: 0 -5px 10px #00000029;
  text-align: center;
}

.storeProfileHeader {
  position: relative;
  height: 200px;
  margin-bottom: 30px;
}

.storePicContainer {
  height: 164px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--secondary-color);
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  position: absolute;
  top: 0px;
}

.storePicContainerNotRounded {
  height: 164px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--secondary-color);
  border-radius: 0 0 0 0;
  overflow: hidden;
  position: absolute;
  top: 0px;
}

.storePicContainer img {
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--secondary-color);
  border-radius: 30px 30px 0 0;
}
.storePicContainerNotRounded img {
  height: auto;
  /* width: 100%; */
  /* width: fit-content; */
  /* max-width: 100%; */
  /* max-height: 100%; */
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 0;
  margin: 0;
  /* background-color: var(--secondary-color); */
  border-radius: 0 0 0 0;
  /* object-fit: cover;*/
}

.storeProfileHeader .avatarContainer {
  position: absolute;
  width: 100%;
  top: 84px;
}

.avatarContainer {
  padding-top: 30px;
}

.avatarButton.MuiIconButton-root {
  width: 64px;
  height: 64px;
  background-color: #ffffff;
  border-radius: 100%;
  padding: 0;
}
.avatarButton.MuiIconButton-root:hover {
  background-color: #ffffff;
}

.avatarButton .MuiSvgIcon-root {
  width: 32px;
  height: 32px;
}
.avatarButton.submit img {
  width: 100%;
  height: 100%;
}

.avatarButton.submit {
  padding: 0;
}

.avatarContainer .avatarEditIcon {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 100%;
}
.previewContainer {
  padding:40px;
  text-align: center;
  position: relative;
}

.previewHeader {
  text-align: center;
  position: relative;
  margin-bottom: 40px;
}

.profileProbability {
  display: inline-block;
  width: 120px;
}

.profileInitMessage {
  width: 60%;
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 40px;
  display: inline-block;
  color: #393939;
}

.profileTitle {
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 1.8px;
  line-height: 26px;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--primary-color);
  width: 100%;
  min-width: 100%;
}

.profileProbability {
  display: inline-block;
  width: 120px;
}

.profileStoreButton {
  position: absolute;
  top: 10px;
  z-index: 100;
  left: 0px;
  width: 100%;
}

.profilePreviewTitle {
  font-family: "MavenPro Medium", Arial, sans-serif;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 26px;
  margin-bottom: 20px;
  color: #343434;
  text-align: center;
}

.profilePreviewTitle3 {
  font-family: "MavenPro Medium", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 39px;
  /*margin-bottom: 20px;*/
  text-align: left;
  width: 90%;
  display: inline-block;
}

.profilePreviewAddress {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  /*margin-bottom: 20px;*/
  text-align: center;
  width: 60%;
  display: inline-block;
}

.profilePreviewDescription {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  margin-bottom: 20px;
  text-align: left;
  width: 90%;
  display: inline-block;
  color: #343434;
}
.profilePreviewText {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  margin-bottom: 20px;
  text-align: left;
  width: 90%;
  display: inline-block;
}

.profilePreview-text p,
.profilePreviewDescription p {
  margin: 4px 4px 0 0;
}

.profilePreviewTitle2 {
  color: var(--primary-color);
  font-family: "Giorgio", Arial, sans-serif;
  font-size: 18px;
  letter-spacing: 0.9px;
  line-height: 26px;
  margin-bottom: 20px;
  text-align: left;
  width: 90%;
  display: inline-block;
  -webkit-text-stroke: 0.7px var(--primary-color);
}

.profilePreviewMap {
  width: 90%;
  height: 250px;
  background-color: #d0e3eb;
  display: inline-block;
  margin-bottom: 40px;
}

.profilePreviewRow {
  display: flex;
  flex-flow: row;
  width: 90%;
}

.profilePreviewRow .profilePreviewTitle4 {
  font-family: "MavenPro Medium", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 39px;
  margin-bottom: 0px;
  text-align: left;
  width: 90%;
  margin-left: 5%;
  display: inline-block;
  flex-grow: 0;
  color: #343434;
  flex-shrink: 3;
}
.profilePreviewRow .profilePreviewText2 {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 39px;
  text-align: left;
  width: 90%;
  color: #343434;
  display: inline-block;
}

.distance {
  color: var(--primary-color);
  font-family: "MavenPro Medium";
  font-size: 14px;
  letter-spacing: 0px;
  position: absolute;
  /*line-height: 46px;*/
  left: 20px;
  bottom: 0px;
}
.category {
  color: var(--primary-color);
  font-family: "MavenPro Medium";
  font-size: 14px;
  letter-spacing: 0px;
  margin-top: 16px;
}

.payButtonContainer {
  position: fixed;
  width: 100%;
  bottom: 20px;
  text-align: center;
}

.logoPay {
  width: 100%;
  height: auto;
}
