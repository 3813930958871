.message {
  font-family: "MavenPro Regular";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  width: 70%;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #343434;
}

.box {
  border-radius: 30px;
  background-color: #ffffff;
  box-shadow: 0 3px 45px #00000029;
  padding-top: 20px;
  text-align: center;
}

.mainContainer {
  height: 100%;
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.actionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.titleContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;
}

.box {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 16px;
  display: flex;
  width: 80%;
  flex-direction: row;
  box-shadow: 0px 10px 35px #00000029;
  max-width: 400px;
  gap: 1em;
  justify-content: center;
  flex: 1;
}

.probabilityText{
  font-size: 13px;
  font-family: 'MavenPro Regular';
  color: black;
  -webkit-text-stroke: 0px;
}

.contentTitle {
  font-family: "Giorgio";
  font-size: 24px;
  letter-spacing: 1.6px;
  text-align: start;
  margin: 20px 0;
  -webkit-text-stroke: 1px var(--primary-color);
  color: var(--primary-color);
}

.contentText{
  font-family: "MavenPro Regular";
  color: #343434;
  font-size: 16px;
  text-align: start;
  line-height: 22px;
  flex: 0 1 50%;
}

.columnsContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  padding: 0.5em 0;
  justify-content: center;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.column{
  display: inline-flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  min-width: 450px;
  gap: 0;
}

.columnTitle{
  color: var(--secondary-color);
  font-size: 17px;
  font-family: 'Giorgio';
  font-weight: 800;
  padding: 1em 0;
}

.feeRow{
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5em;
  font-family: "MavenPro Regular";
  color: #343434;
  font-size: 16px;
  text-align: start;
  line-height: 22px;
  padding: 0 0.5em;
}


.feeValue{
  font-size: 15px;
  color: var(--primary-color);
  font-family: 'MavenPro Bold';
  width: 50px;
}

.footer{
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  font-family: "MavenPro Regular";
  color: #343434;
  font-size: 13px;
  text-align: start;
  /* line-height: 22px; */
  padding: 1.5em 0;  
  align-items: center;
  gap: 1em;
}

.footerValues{
  font-size: 17px;
  font-family: 'Giorgio';
  color: var(--primary-color);
  align-items: center;
}


.dialogTitle{
  font-size: 16px;
  font-family: 'Giorgio';
  -webkit-text-stroke: .5px var(--primary-color);
  color: var(--primary-color);
  display: flex;
  align-self: flex-end;
  padding: 1em 0;
}

.container {
  text-align: center;
  padding: 0;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-items: center;
  min-width: 430px;
}

.top {

}

.info {
  font-family: "MavenPro Regular";
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #606060;
  margin-bottom: 16px;
}

.whiteBox {
  border-radius: 30px;
  background-color: #ffffff;
  width: 100%;
  padding-bottom: 150px;
  padding-top: 15px;
  margin: 0;
  box-shadow: 0 3px 45px #00000029;
  text-align: center;
  flex-grow: 1;
}

.periodLabel {
  font-family: "MavenPro Regular";
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 17px;
  color: #848484;
  margin-top: 13px;
}

.rowContainer {
  display: inline-block;
  width: 90%;
}
.row {
  margin-top: 5px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.rowBlue {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-bottom: 2px solid var(--primary-color);
  padding-top: 8px;
  padding-bottom: 8px;
}
.rowSmall {
  margin-top: 10px;
  border: none;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.label0 {
  align-self: flex-start;
  font-family: "MavenPro Medium";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 27px;
  color: #343434;
}
.label1 {
  align-self: flex-start;
  font-family: "MavenPro Medium";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #343434;
}
.label2 {
  align-self: flex-start;
  font-family: "MavenPro Regular";
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #343434;
}
.label3 {
  align-self: flex-start;
  font-family: "MavenPro Regular";
  font-size: 9px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #343434;
}
.value0 {
  align-self: flex-end;
  font-family: "Giorgio";
  font-size: 22px;
  letter-spacing: 0px;
  line-height: 25px;
  color: var(--primary-color);
}

.value1 {
  align-self: flex-end;
  font-family: "Giorgio";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  color: var(--primary-color);
}

.wintext {
  align-self: flex-end;
  font-family: "MavenPro Medium";
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 22px;
  color: var(--secondary-color);
}
.losstext {
  align-self: flex-end;
  font-family: "MavenPro Medium";
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #606060;
}
