.label {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: "13px";
}

.ownerOptions {
  padding-left: 16px;
  padding-right: 16px;
}
.separator {
  margin-top: 20px;
}

.formDataContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.radioMessage {
  text-align: start;
  margin-top: 48px; 
  margin-bottom: 36px;
  font-family: "MavenPro Regular";
  color: var(--primary-color);
}
