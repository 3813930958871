.message {
  font-family: "MavenPro Regular";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  width: 70%;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #343434;
}

.box {
  border-radius: 30px;
  background-color: #ffffff;
  box-shadow: 0 3px 45px #00000029;
  padding: 30px;
  width: 100%;
  /* max-width: 600px; */
}

.mainContainer {
  text-align: center;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  /* flex-direction: column; */
  /* justify-items: center; */
  /* align-items: center; */
  padding-bottom: 40px;
}


.formContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px; 
}

.actionContainer {
  
}
