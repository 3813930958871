.container {
  /* text-align: center; */
  box-sizing: border-box;
  padding-bottom: 120px;
  width: 100%;
  /* max-width: 1024px; */
}

.block {
  width: 70%;
  display: inline-block;
}

.welcomeHeader {
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 3px;
  color: var(--primary-color);
  margin-bottom: 30px;
  margin-top: 15%;
  line-height: 30px;
  -webkit-text-stroke: 0.7px var(--primary-color);
}

.welcomeMessage {
  color: #01a1df;
  letter-spacing: 1.3px;
  font-family: "Giorgio", Arial, sans-serif;
  font-size: 20px;
  text-align: start;
  margin: 48px 0;
  -webkit-text-stroke: 0.5px;
}

.infoMessage {
  color: #343434;
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 16px;
  text-align: start;
  line-height: 22px;
  margin: 48px 0;
}

.headerMessage {
  color: #393939;
  letter-spacing: 0px;
  margin-top: 10px;
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 15px;
  width: 150px;
  display: inline-block;
  margin-bottom: 50px;
}

.actionsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 24px;
}

.roundIcon {
  min-width: 0;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-image: none;
  background-color: var(--secondary-color);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reqBox {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 16px;
  display: flex;
  width: 80%;
  flex-direction: row;
  box-shadow: 0px 3px 15px #00000029;
  max-width: 400px;
  /* margin: 20px auto; */
}

.left {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right {
  flex-shrink: 1;
  display: flex;
}

.title {
  font-family: "Giorgio";
  font-size: 14px;
  letter-spacing: 1.4px;
  line-height: 20px;
  color: var(--primary-color);
  -webkit-text-stroke: 0.7px var(--primary-color);
}

.status {
  font-family: "MavenPro Regular";
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #343434;
}

.message {
  font-family: "MavenPro Regular";
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #343434;
}

.contentContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 2%;
  row-gap: 20px;
}

.onlineStoreContainer {
  grid-area: online;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 5%;
  margin-bottom: 32px;
}

.headerContent {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 50%;
  align-items: center;
}


@media  (max-width: 768px) {
  .actionsContainer{
  align-items: center;

  }
}
