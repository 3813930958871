.container {
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 2px 0px 2px 12px;
    max-height: 35px;
}

.label {
    font-size: 14px;
    font-family: 'MavenPro Regular';
    color: #9F9F9F;
    line-height: 24px;
}

.deleteIcon {
    width: 10px;
    height: 10px;
}
