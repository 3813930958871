.login-root {
  /* background-color: var(--primary-color); */
  /* background-image: linear-gradient(var(--primary-color), #005170); */
  text-align: center;
  margin: 0;
  padding: 0px;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.login-root.green {
  background-color: var(--secondary-color);
}

.login-root.gray {
  background-color: #f5f5f5;
  background-image: none;
}

.background {
  min-width: 100%;
  min-height: 100vh;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  object-fit: cover;
}

.background .background {
  width: 100%;
  height: 100%;
  min-width: 1200px;
}
.background .logo {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 170px;
}

.background .title {
  position: absolute;
  top: 64px;
  left: 24px;
  width: 170px;
  user-select: none;
  color: #ffffff;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.7px;
  font-family: "Giorgio";
}

.empty {
  display: block;
  height: 40px;
  min-height: 40px;
  content: " ";
  flex-basis: 60px;
  flex-shrink: 0;
}

.login-progress-wrapper {
  /* margin-top: 20px; */
  margin-bottom: 20px;
  width: 70%;
  display: inline-block;
  flex-basis: 40px;
  flex-shrink: 0;
}

.login-progress-step {
  display: inline-block;
  font-weight: bold;
  color: #bbbbbb;
  font-family: "BalooBhaina2 Regular";
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: 12px;
}

.login-root .content-container {
  box-sizing: border-box;
  border-radius: 30px;
  background-color: #ffffff;
  width: 100%;
  min-width: 400px;
  max-width: 33%;
  margin: 96px 0;
  display: flex;
  align-items: center;

  z-index: 100;
  flex: 0 1 auto;
  box-shadow: 0 -3px 45px #00000029;
  padding: 32px;
  /*position: absolute;
  bottom: -80px;*/
}

.login-root.green .content-container {
  box-shadow: none;
}

h3 {
  margin-top: 0px;
}

.content {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}

/* .content .MayPPage-root{
  height: 100%;
} */

.country-code {
  text-align: right;
  padding: 10px 5px;
  color: var(--primary-color);
}

.login-root h3 {
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 30px;
  color: var(--primary-color);
  -webkit-text-stroke: 0.7px var(--primary-color);
}

.login-root .message {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #343434;
  width: 90%;
  display: inline-block;
  text-align: center;
  margin-bottom: 30px;
}

.login-root .message.error {
  color: #ff0000;
}

.login-root .wrong-code {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 21px;
  color: #ff0000;
  width: 70%;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
  content: " ";
}

.login-root .message2 {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.45px;
  line-height: 22px;
  color: #000000;
  width: 70%;
  display: inline-block;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 80px;
}

.login-input {
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 25px;
  letter-spacing: 2.5px;
  line-height: 26px;
  color: var(--primary-color);
}

.login-input2 {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-top: 20px;
  width: 100%;
  text-align: left;
}

.login-input2 .MuiFormControl-root {
  width: 100%;
}

.form-data-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.form-data {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-top: 20px;
  text-align: left;
}

.form-data.w100 .MuiFormControl-root {
  width: 100%;
}

.form-data.w100 {
  width: 100%;
}

.form-data.w50 {
  width: 45%;
}

.form-data.w50 > * {
  width: 100%;
}

.form-data.w30 {
  width: 25%;
}
.form-data.w70 {
  width: 70%;
}

.form-data.w100 .MuiFormControl-root {
  width: 100%;
}

.login-input-group {
  display: inline-block;
  text-align: center;
  width: auto;
  margin-top: 20px;
}

.bottom-notes {
  display: inline-block;
  padding: 0;
  /*position: absolute;
  bottom:50px;*/
  left: 15%;
  width: 70%;
  margin: 0;
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 16px;
  vertical-align: baseline;
  align-self: center;
}

.bottom-link {
  color: #3fe7ac;
  font-family: "MavenPro Medium", Arial, sans-serif;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.step3 {
  display: inline-block;
  /*min-height: 800px;*/
  width: 100%;
}

.login-page-back {
  margin-top: 32px;
}

.MayPPage-root {
  height: 100%;
}

@media screen and (max-height: 640px) {
  .login-root .message2 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {

  .login-root .content-container {
    border-radius: 30px;
    background-color: #ffffff;
    width: 100%;
    min-width: 90%;
    max-width: 33%;
    margin: 96px 0;
    display: flex;
    align-items: center;
  
    z-index: 100;
    flex: 0 1 auto;
    box-shadow: 0 -3px 45px #00000029;
    padding: 32px;
    /*position: absolute;
    bottom: -80px;*/
  }
}
