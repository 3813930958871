.label {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: "13px";
}

.ownerOptions {
  margin-top: 30px;
  padding-left: 10%;
  padding-right: 10%;
}
.separator {
  margin-top: 20px;
}

.secure {
  display: flex;
  /*align-items: center;*/
  text-align: left;
  margin-top: 30px;
}
.secureBlue {
  font-family: "MavenPro Medium";
  font-size: "15px";
  color: var(--primary-color);
  line-height: 21px;
  letter-spacing: 0px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 33px;
}
.formDataContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.info {
  font-family: "MavenPro Regular";
  font-size: 14px;
  color: #343434;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;
}
