.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}

.contentContainer {
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0 3px 45px #00000029;
  padding: 30px;
}

.buttonContainer {
  /* width: 100%;
  display: flex;
  justify-content: flex-end; */
}

.noStoresMessage {
  min-width: 400px;
  min-height: 164px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 32px;
}


@media (max-width: 600px) {
  .contentContainer {
    display: flex;
    justify-content: center;
  }

}
