.MayPPage-root {
  text-align: center;
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 2.4px;
  line-height: 40px;
  height: auto;
  margin: 0;
  padding-bottom: 0px;
}

.MayPPage-root .MuiTypography-body1 {
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 2.4px;
  line-height: 40px;
}

.profileMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-crop-container {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #f0f0f0;
  z-index: 1800;
}

.crop-select {
  position: absolute;
  bottom: 50px;
  left: 20px;
  z-index: 1801;
}

.crop-submit {
  position: absolute;
  bottom: 50px;
  right: 20px;
  z-index: 1801;
  display: flex;
  justify-content: center;
}

.avatar-container_ {
  padding-top: 30px;
}

.avatar-button.MuiIconButton-root {
  width: 64px;
  height: 64px;
  background-color: #ffffff;
  border-radius: 100%;
  padding: 0;
}
.avatar-button.MuiIconButton-root:hover {
  background-color: #ffffff;
}

.avatar-button .MuiSvgIcon-root {
  width: 32px;
  height: 32px;
}
.avatar-button.submit img {
  width: 100%;
  height: 100%;
}

.avatar-button.submit {
  padding: 0;
}

.avatar-container .avatar-edit-icon {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 100%;
  cursor: pointer;
}

.form-data-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.form-data {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-top: 20px;
  text-align: left;
}

.form-data.w100 .MuiFormControl-root {
  width: 100%;
}

.form-data.w100 {
  width: 100%;
}

.form-data.w50 {
  width: 45%;
}

.form-data.w100 .MuiFormControl-root {
  width: 100%;
}

.store-content.profile {
  border-radius: 30px;
  background-color: #ffffff;
  width: 100%;
  padding-bottom: 30px;
  padding-top: 0px;
  margin: 0;
  box-shadow: 0 -5px 10px #00000029;
  max-width: 1024px;
  margin: 20px 20px;
}

.store-profile-header {
  position: relative;
  height: 356px;
}

.store-pic-container {
  width: 100%;
  height: 256px;
  padding: 0;
  margin: 0;
  background-color: var(--secondary-color);
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  position: absolute;
  top: 0px;
}

.store-pic-container img {
  object-fit: cover;
  height: 256px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--secondary-color);
  border-radius: 30px 30px 0 0;
}

.store-profile-header .avatar-container {
  position: absolute;
  /* width: 100%; */
  top: 164px;
  left: calc(50% - 64px);
}

.profile-init-message {
  text-align: center;
  width: 100%;
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 40px;
  display: inline-block;
  color: #393939;
}

.profile-title {
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 1.8px;
  line-height: 26px;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--primary-color);
  width: 100%;
  min-width: 100%;
  -webkit-text-stroke: 0.7px var(--primary-color);
}

.profile-probability {
  height: 28px;
  width: 115px;
  color: #ffffff;
  background-color: var(--secondary-color);
  border-radius: 30px;
  font-family: "BalooBhaina2 Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 30px;
  display: inline-block;
}

.profile-probability-message {
  width: 60%;
  color: #000000;
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 15px;
  display: inline-block;
  margin-top: 20px;
}

.profile-store-button {
  position: absolute;
  top: 10px;
  z-index: 100;
  left: 0;
  width: 100%;
  text-align: center;
}

.profile-preview-title {
  font-family: "MavenPro Medium", Arial, sans-serif;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 23px;
  margin-bottom: 20px;
}

.profile-preview-title3 {
  font-family: "MavenPro Medium", Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0px;
  /*line-height: 39px;*/
  /*margin-bottom: 20px;*/
  text-align: left;
  width: 90%;
  display: inline-block;
}

.profile-preview-address {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  margin-bottom: 50px;
  text-align: center;
  width: 60%;
  display: inline-block;
}

.profile-preview-description {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: left;
  width: 90%;
  display: inline-block;
}
.profile-preview-text {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: left;
  width: 90%;
  display: inline-block;
}

.profile-preview-text p,
.profile-preview-description p {
  margin: 4px 4px 0 0;
}

.profile-preview-title2 {
  color: var(--primary-color);
  font-family: "Giorgio", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 2.4px;
  line-height: 40px;
  margin-bottom: 20px;
  text-align: left;
  width: 90%;
  display: inline-block;
}

.profile-preview-map {
  width: 90%;
  height: 250px;
  background-color: #d0e3eb;
  display: inline-block;
}

.profile-preview-row {
  display: flex;
  flex-flow: row;
  width: 90%;
}

.profile-preview-row .profile-preview-title4 {
  font-family: "MavenPro Medium", Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 39px;
  margin-bottom: 0px;
  text-align: left;
  width: 90%;
  margin-left: 5%;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 3;
}
.profile-preview-row .profile-preview-text2 {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 39px;
  text-align: left;
  width: 90%;
  display: inline-block;
}

.tabContent {
  padding-top: 40px;
}

.tabContent.center {
  display: flex;
  justify-content: center;
  margin: 0 40px;
}

@media  (max-width: 768px) {
  .tabContent.center {
    display: flex;
    justify-content: center;
    margin: 0 8px;
  }
}
