.mainContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.sectionContainer {
  border-radius: 30px;
  background-color: #ffffff;
  /*padding-bottom: 150px;*/
  padding: 30px;
  box-shadow: 0 3px 45px #00000029;
  text-align: center;
  margin-top: 40px;
}

.sectionContainer .title {
  margin: 20px 0;
}

.showKeysContainer {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 8px;
  text-align: start;
}

.buttonContainer {
  /* display: flex;
  width: 100%;
  justify-content: flex-end; */
}

.addContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  min-width: 320px;
}

@media (max-width: 480px) {
  .showKeysContainer {
    min-width: 200px;
  }

}
