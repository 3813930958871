.mainContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.message {
  font-family: "MavenPro Regular";
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #393939;
  width: 70%;
  display: inline-block;
  margin-bottom: 20px;
}

.info {
  font-family: "MavenPro Regular";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #343434;
  width: 70%;
  display: inline-block;
  margin-bottom: 20px;
}

.whiteBox {
  border-radius: 30px;
  background-color: #ffffff;
  /*padding-bottom: 150px;*/
  padding: 40px 16px;
  margin: 0;
  box-shadow: 0 3px 45px #00000029;
  text-align: center;
  margin-bottom: 40px;
}

.loadingContainer {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.closeCashRegisterBox {
  margin-top: 40px;
  /*margin-bottom: 20px;*/
  align-self: center;
}

.currentDateTimeLabel {
  font-family: "MavenPro Medium";
  font-size: 18px;
  letter-spacing: -0.18px;
  line-height: 21px;
  color: var(--primary-color);
  margin-bottom: 15px;
}

@media  (max-width: 768px) {
  .whiteBox{
    background-color: transparent!;
    box-shadow: none;
  }
}
