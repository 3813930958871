.formDataContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.formLabel {
  font-family: "MavenPro Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #343434;
  line-height: 21px;
  text-align: left;
  padding-top: 16px;
}

.profile_title {
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 1.8px;
  line-height: 26px;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--primary-color);
  width: 100%;
  min-width: 100%;
  -webkit-text-stroke: 0.7px var(--primary-color);
}
