.label {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: "13px";
}

.ownerOptions {
  margin-top: 30px;
  padding-left: 10%;
  padding-right: 10%;
}
.separator {
  margin-top: 20px;
}

.container {
  text-align: center;
  padding: 0;
  margin: 0;
  position: relative;
  padding-top: 60px;
  background-color: #f5f5f5;
}

.content {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-items: center;
  text-align: center;
}

.whiteBox {
  box-sizing: border-box;
  border-radius: 30px;
  background-color: #ffffff;
  width: 100%;
  /* max-width: 600px; */
  /* height: 100%; */
  margin-bottom: 40px;
  max-height: 500px;
  padding: 24px;
  margin: 0;
  box-shadow: 0 3px 45px #00000029;
  text-align: center;
  margin-top: 20px;
  flex-grow: 1;
}

.info {
  font-family: "MavenPro Regular";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #343434;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 70%;
  align-self: center;
}

.title {
  font-family: "MavenPro Medium";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 41px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 40px;
}

.separator {
  margin-top: 20px;
}
.secure {
  display: flex;
  /*align-items: center;*/
  text-align: left;
  margin-top: 30px;
  padding: 0px 24px;
}
.secureBlue {
  font-family: "MavenPro Medium";
  font-size: "15px";
  color: var(--primary-color);
  line-height: 21px;
  letter-spacing: 0px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 33px;
}
.info2 {
  font-family: "MavenPro Regular";
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #343434;
  margin-bottom: 20px;
  align-self: center;
  padding: 0px 24px;
  text-align: left;
}

.formContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
}

.actionContainer {
  /* width: 100%;
  display: flex;
  justify-content: flex-end; */
}
