.container {
  text-align: center;
  padding: 0;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
}

.info {
  font-family: "MavenPro Regular";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #343434;
  width: 70%;
  display: inline-block;
  margin-bottom: 20px;
}

.whiteBox {
  border-radius: 30px;
  background-color: #ffffff;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 40px;
  margin: 0;
  box-shadow: 0 3px 45px #00000029;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  flex-grow: 1;
}

.date {
  font-family: "MavenPro Medium";
  font-size: 11px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0;
  color: var(--primary-color);
}

.rowContainer {
  display: inline-block;
  width: 90%;
  margin-top: 14px;
}
.row {
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
}

.rowTop {
}

.rowMiddle {
  flex-grow: 1;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.amount {
  font-family: "Giorgio";
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 24px;
  color: var(--primary-color);
  text-align: right;
}

.paymentDescription {
  font-family: "MavenPro Medium";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 41px;
  color: #343434;
  text-align: left;
}
.paymentId {
  font-family: "MavenPro Regular";
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #343434;
  text-align: left;
}
.paymentStatus {
  font-family: "MavenPro Regular";
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #343434;
  text-align: left;
}

.creditBox {
  /* margin-top: 32px; */
  margin-bottom: 32px;
  text-align: left;
}

.creditLabel {
  font-family: "MavenPro Medium";
  font-size: 18px;
  letter-spacing: -0.18px;
  line-height: 21px;
  color: var(--secondary-color);
  margin-bottom: 15px;
}

.creditValue {
  font-family: "Giorgio";
  font-size: 32px;
  letter-spacing: 0px;
  line-height: 38px;
  color: var(--primary-color);
}


@media  (max-width: 768px) {
  .creditLabel, .creditValue{
    text-align: center;

  }
}
