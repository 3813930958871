.MayPPage-root {
  text-align: center;
  /*padding-bottom: 100px;*/
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 2.4px;
  line-height: 40px;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  padding-top: env(safe-area-inset-top);
}

.MayPPage-root {
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 1.6px;
  line-height: 22px;
  color: #343434;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  /*padding-top: 80px;*/
  /*padding-top: 35px;*/
}

.mainContainer {
  min-height: calc(100vh - 60px);
  width: 100%;
  /*min-width: 100vw;/*var(--min-width);*/
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  /* background-image: url( "../../assets/background.svg" );
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% auto; */
  overflow-x: auto;
}

.mainContainer.menuOpen {
  margin-left: 20vw;
}

.mainContainer.menuClosed {
  margin-left: 0;
}

.crop-container {
  display: inline-block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: #f0f0f0;
  z-index: 1800;
}

.crop-select {
  position: absolute;
  bottom: 50px;
  left: 20px;
  z-index: 1801;
}

.crop-submit {
  position: absolute;
  bottom: 50px;
  right: 20px;
  z-index: 1801;
}

.avatar-container {
  padding-top: 30px;
}

.avatar-button.MuiIconButton-root {
  width: 64px;
  height: 64px;
  background-color: #ffffff;
  border-radius: 100%;
  padding: 0;
}
.avatar-button.MuiIconButton-root:hover {
  background-color: #ffffff;
}

.avatar-button .MuiSvgIcon-root {
  width: 32px;
  height: 32px;
}
.avatar-button.submit img {
  width: 100%;
  height: 100%;
}

.avatar-button.submit {
  padding: 0;
}

.avatar-container .avatar-edit-icon {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 100%;
}

.phone-number {
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 32px;
  margin-top: 20px;
}

.app-version {
  font-family: "Giorgio", Arial, sans-serif;
  font-weight: normal;
  font-size: 10px;
  letter-spacing: 1.5px;
  line-height: 25px;
  color: #606060aa;
  text-align: right;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.menuContainer {
  padding-top: 60px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}
