.probability {
  display: flex;
  /*height: 24px;
  width: 93px;*/
  background-color: var(--secondary-color);
  border-radius: 24px;
  justify-content: center;
  /*align-items: center;*/
  align-self: flex-end;
  padding: 2px 16px;
  column-gap: 8px;
  gap: 0;
  box-sizing: border-box;
}

.probabilityText {
  font-family: "BalooBhaina2 SemiBold";
  font-size: 15px;
  padding: 0px;
  line-height: 24px;
  color: #ffffff;
  /* flex-grow: 1; */
  text-align: center;
  margin-top: 2px;
  letter-spacing: normal;
  white-space: nowrap;
}
.probabilityLogo {
  height: 24px;
  padding: 0px;
  color: #ffffff;
  flex-shrink: 2;
  display: flex;
}

.probabilityLogo img {
  /*padding: 4px;*/
  width: 16px;
  padding-right: 8px; /*cssIOS*/
  height: 100%;
}