.spinnerContainer {
  position: relative;
  margin: 0;
  padding: 0;
}

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: none;
}
.spinner.hide {
  display: block;
}

.spin {
  position: absolute;
  height: 48px;
  width: 48px;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  z-index: 0;
}

.spinnerContent.hide {
  visibility: hidden;
}
