.container {
  text-align: center;
  padding: 0;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-items: center;
  min-width: 364px;
  padding: 0 24px;
  padding-bottom: 12px;
}

.header {
  width: 100%;
  flex: 0 1 100%;
  display: flex;
  justify-content: space-between;

}

.title {
  font-family: "Giorgio";
  font-size: 19px;
  line-height: 22px;
  color: var(--primary-color);
  -webkit-text-stroke: 0.5px var(--primary-color);
}

.whiteBox {
  border-radius: 30px;
  background-color: #ffffff;
  width: 100%;
  padding-bottom: 150px;
  padding-top: 15px;
  margin: 0;
  box-shadow: 0 3px 45px #00000029;
  text-align: center;
  flex-grow: 1;
}

.periodLabel {
  font-family: "MavenPro Regular";
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 17px;
  color: #848484;
  margin-top: 13px;
}

.rowContainer {
  display: inline-block;
  width: 100%;
}
.row {
  margin-top: 5px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  /*border-bottom: 1px solid #D8D8D8;*/
}
.rowBlue {
  /*margin-top: 20px;*/
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-bottom: 2px solid var(--primary-color);
  padding: 12px 0;
}

.boxBlue {
  /*margin-top: 20px;*/
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--primary-color);
  padding: 12px 0;
}

.rowSmall {
  margin-top: 10px;
  border: none;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.label0 {
  align-self: flex-start;
  font-family: "MavenPro Medium";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 27px;
  color: #343434;
}
.label1 {
  align-self: flex-start;
  font-family: "MavenPro Medium";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #343434;
}
.label2 {
  align-self: flex-start;
  font-family: "MavenPro Regular";
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #343434;
}

.label3 {
  align-self: flex-start;
  font-family: "MavenPro Regular";
  font-size: 9px;
  letter-spacing: 0px;
  line-height: 17px;
  color: #343434;
}

.value0 {
  align-self: flex-end;
  font-family: "Giorgio";
  font-size: 21px;
  letter-spacing: 0px;
  line-height: 25px;
  color: var(--primary-color);
}

.value1 {
  align-self: flex-end;
  font-family: "MavenPro Medium";
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 22px;
  color: var(--primary-color);
}

.wintext {
  align-self: flex-end;
  font-family: "MavenPro Medium";
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 22px;
  color: var(--secondary-color);
}
.losstext {
  align-self: flex-end;
  font-family: "MavenPro Medium";
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #606060;
}
