.mainContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 32px 64px;
  row-gap: 24px;
}

.pageTitle {
  font-family: "Giorgio";
  font-size: 24px;
  letter-spacing: 1.6px;
  text-align: start;
  margin: 20px 0;
  -webkit-text-stroke: 1px var(--primary-color);
  color: var(--primary-color);
}

.headerContainer{

}

.secondHeaderContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  margin-bottom: 32px;
}

.pageDescription {
  font-family: "MavenPro Regular";
  color: #343434;
  font-size: 16px;
  /*letter-spacing: 1.6px;*/
  text-align: start;
  line-height: 22px;
  /*-webkit-text-stroke: 0.5px;*/
  flex: 0 1 50%;
}

.pageAction {
  flex: 0 1 50%;
  display: flex;
  justify-content: flex-end;
}

.content {
    /* margin-top: 48px; */
}


@media  (max-width: 768px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 32px 16px;
    gap: 24px;
  }

  .secondHeaderContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-bottom: 32px;
    gap: 22px;
  }

  .pageDescription, .pageAction {
    text-align: center;
    flex-grow: 1;
    flex-basis: 100%;
  }

  .pageAction{
    display: flex;
    justify-content: center;
  }
  .headerContainer{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
