.container:hover {
  cursor: pointer;
}


.storeName {
  text-align: center;
  max-width: 128px;
  margin: 12px auto;
  word-break: break-word;
  font-family: "MavenPro Medium";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
