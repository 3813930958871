/*.container{
  border-radius: 20px;
  height: 109px;
  margin:0px;
  padding:0px;
  display:flex;
  flex-flow: row;
  margin-top: 16px;
  box-shadow: #00000029 0 3px 6px;
  background-color: #ffffff;
  position: relative;
  width: 100%;
  min-width: inherit;
}*/

.wrap {
  position: relative;
  margin: 0;
  padding: 0;
  width: 415px;
  min-width: 415px;
}

.roundIcon {
  min-width: 0;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-image: none;
  background-color: var(--secondary-color);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarName {
  height: 100%;
  width: 100%;
  font-size: 48px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.storePicContainer {
  border-radius: 20px 0px 0px 20px;
  margin: 0;
  height: 100%;
  min-width: 126px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  background-color: var(--primary-color);
}
.storePic {
  border-radius: 20px 0px 0px 20px;
  margin: 0;
  height: 124px;
  width: 100%;
  /* min-width: 100%; */
  display: block;
  position: absolute;
  top: 50%;
  background-color: var(--primary-color);
  border: none;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.storeInfo {
  display: inline-block;
  width: auto;
  flex-grow: 1;
  padding: 12px;
  text-align: left;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 90px;
}

.storeTitle {
  font-family: "MavenPro Medium";
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  /*line-height: 30px;*/
  color: #343434;
}

.storeAddress {
  margin-top: 5px;
  font-family: "MavenPro Regular";
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 15px;
  color: #343434;
  flex-grow: 1;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.storeSmallText {
  font-family: "MavenPro Medium";
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 20px;
  color: var(--primary-color);
}

.alertMessage {
  color: var(--alert-color);
}

.bottom {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.preferenceButton {
  position: absolute;
  top: -10px;
  right: 0px;
}

@media (max-width: 768px) {
  .wrap {
    width: 100%;
    min-width: 150px;
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  /* .storeAddress {
    display: none;
  } */

  .wrap {
    width: 100%;
    min-width: 280px;
    max-width: 400px;
  }

  .storePicContainer {
    min-width: 96px;
  }

  /* .preferenceButton {
    display: none;
  } */
}
