.container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label {
    font-family: 'Giorgio';
    font-size: 18px;
    color: var(--primary-color);
    -webkit-text-stroke: 0.5px var(--primary-color);
    transform: translateY(3px);
}

.checkbox{
    border: none;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox > img {
    width: 42px;
    height: 42px;
}

.outerCircle{
    width: 22px;
    height: 22px;
    border: 2.5px solid var(--primary-color);
    border-radius: 50%;
    position: relative;
    background-color: transparent;
    cursor: pointer;
}

.innerCircle{
    width: 11px;
    height: 11px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    /* bottom: 0; */
    left: 50%;
    /* right: 0; */
    background-color: var(--primary-color);
    cursor: pointer;
    transform: translate(-50%, -50%)
}
