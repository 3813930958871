.mainContainer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.headerContainer {
  display: flex;
  justify-content: flex-start;
  gap: 48px;
  align-items: flex-start;
}

.tableActionContainer {
  flex-grow: 1;
  justify-self: flex-end;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}

.datepicker {
  max-width: 120px;
  text-align: center;
}

.inputContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  min-height: 60px;
  padding-top: 16px;
}

.inputLabel {
  font-family: "Giorgio";
  font-size: 17px;
  line-height: 20px;
  color: var(--secondary-color);
  -webkit-text-stroke: 1px var(--secondary-color);
  padding-right: 12px;
  padding-top: 12px;
}

@media  (max-width: 1368px) {
  .headerContainer{
    justify-content: center;
    flex-wrap: wrap;
  }

  .tableActionContainer {
    justify-content: center;
  }
}

