.presentazione-root {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0px;
  position: fixed;
  top: 0;
  padding-top: 20px;
}

.carousel {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: linear-gradient(to bottom, var(--primary-color), #426cb4);
}

.carousel .rec-slider-container {
  width: 100%;
  margin: 0;
}

.carousel .rec {
  height: 100%;
  margin: 0;
}

.carousel .rec-carousel {
  width: 100%;
  height: 100%;
  margin: 0;
}

.carousel .rec-arrow {
  display: none;
}

.carousel .rec-item-wrapper {
  width: 100%;
  height: 100%;
}

.carousel .scheda {
  width: 100%;
}

.carousel .rec-pagination {
  width: auto;
  position: absolute;
  bottom: 0;
  text-align: center;
}

.carousel .pagination {
  width: auto;
  display: inline-block;
  text-align: center;
}

.carousel .pagination-wrapper {
  width: auto;
  display: flex;
  text-align: center;
}

.carousel .rec-dot {
  transition: all 250ms ease;
  font-size: 1.3em;
  content: "";
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 8px;
  background-color: #ffffff;
  box-shadow: none;
  cursor: pointer;
}

.carousel .rec-dot_active {
  background-color: var(--secondary-color);
  box-shadow: 0px 0px 4px var(--secondary-color);
}

.carousel .rec-dot:hover {
  box-shadow: none;
}

.carousel .scorri {
  color: var(--secondary-color);
  letter-spacing: 2px;
  margin-top: 10px;
  font-family: "Giorgio", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 1.6px;
  -webkit-text-stroke: 0.7px var(--secondary-color);
}

.carousel .salta {
  margin-top: calc(2vh + 10px);
  margin-bottom: calc(2vh + 10px);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
}

.carousel .salta-btn {
  color: #ffffff;
  letter-spacing: 0px;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
  font-family: "MavenPro Regular", Arial, sans-serif;
}

.page {
  padding-top: calc(40vh - 180px);
  text-align: center;
}

.page-title {
  margin-top: 44px;
  color: #ffffff; /*var(--secondary-color);*/
  font-size: 22px;
  letter-spacing: 1.1px;
  line-height: 30px;
  font-family: "Giorgio", Arial, sans-serif;
  -webkit-text-stroke: 0.7px #ffffff;
}
.page-content {
  margin-top: 25px;
  color: #ffffff;
  font-family: "MavenPro Regular", Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  letter-spacing: 0px;
}

.page-icon-box {
  min-width: 124px;
  min-height: 124x;
  width: 124px;
  height: 124px;
  max-width: 175px;
  max-height: 175px;
  display: inline-block;
  background-color: transparent;
}

.page-icon-box img {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.page-title2 {
  margin-top: 20px;
  color: var(--secondary-color);
  font-size: 16px;
  letter-spacing: 2.4px;
  font-weight: normal;
  font-family: "Giorgio", Arial, sans-serif;
}

.round-button-container {
  margin-top: 100px;
}

.arrowContainer{
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 20px;
}

.arrow{
  color: #ffffff;
  min-width: 48px;
  width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 48%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 20px;
  padding: 16px;
  box-sizing: border-box;
}
.arrow:hover{
  background-color: var(--secondary-color);
}

.arrow img{
  height: 100%;
  width: auto;
}

@media (pointer:none), (pointer:coarse) {
  .arrowContainer{
    display: none;
  }
  .page {
    padding-top: calc(40vh - 140px);
  }
}

@media  (max-height: 575.98px) and (orientation: landscape){
  .carousel .salta {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .page {
    padding-top: calc(40vh - 140px);
  }

  .page-icon-box {
    border-radius: 50%;
    min-width: 70px;
    min-height: 70px;
    width: 80px;
    height: 80px;
    max-width: 175px;
    max-height: 175px;
  }

  .carousel .scorri {
    margin-top: 5px;
  }

  .carousel .salta-btn {
    padding: 5px;
  }

  .page-title {
    margin-top: 5px;
    /*font-size: 1.3em;*/
  }
  .page-content {
    margin-top: 5px;
    /*font-size: 0.9em;*/
  }
  .page-title2 {
    margin-top: 10px;
  }

  .round-button-container {
    margin-top: 40px;
  }
  .arrowContainer{
    display: flex;
  }

  .carousel .rec-pagination {
    display: none;
  }
}

@media (max-height: 680px) and (orientation: portrait) {
  .page {
    padding-top: 30px;
  }

  .arrowContainer{
    display: none;
  }
  
}


